/* eslint-disable */
$(function() {
	/*main slider*/

	$('.js-main-slider').slick({
		fade: true,
		arrows: true,
		appendArrows: $('.main-slider__nav'),
		prevArrow: '<button class="button-slick button-slick--prev slick-arrow"><svg class="svg-icon" viewBox="0 0 43 24.8" width="28px" height="16px" fill="#ff5656"><use xlink:href="#svg-arrow-left"></use></svg></button>',
		nextArrow: '<button class="button-slick button-slick--next slick-arrow"><svg class="svg-icon" viewBox="0 0 43 24.8" width="28px" height="16px" fill="#ff5656"><use xlink:href="#svg-arrow-right"></use></svg></button>',
		appendDots: $('.main-slider__nav'),
		dots: true,
		dotsClass: 'main-slider-dots',
		autoplay: true,
  		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: false
				}
			}
		]
	});


	function normalize(val, min, max) {
		var result = Math.min((val - min) / (max - min), 1);
		return result < 0 ? 0 : result;
	}

	function circleAnimation($elem) {
	    var scroll = $(window).scrollTop(),
	    	windowHeight = $(window).innerHeight(),
	    	elemTop = $elem.offset().top,
	    	elemBottom = elemTop + $elem.outerHeight(),
	    	progress = normalize(scroll + windowHeight, elemTop, elemBottom),
	    	$animated = $elem.find('.js-animate');

	    if(elemTop <= (scroll + windowHeight) ){
	    	$animated.css({
	    		'transform' : 'scale(' + progress + ')'
	    	})
	    }
	}

	$(window).scroll(function () {
	    $('.js-animate-container').each(function() {
	    	circleAnimation($(this))
	    });
	});

	$('.js-animate').each(function () {
        circleAnimation($(this))
    });

	/*fixed header*/

	function checkScroll(){
		var $header = $('.js-header'),
			headerHeight = $header.outerHeight();

		if($(window).scrollTop() > headerHeight){
			$header.addClass('is-fixed')
		} else {
			$header.removeClass('is-fixed')
		}
	}

	checkScroll();
	$(window).on('scroll', checkScroll);

	/*search*/

	$('.js-search').each(function(){
		var $this = $(this),
			$otherSearch = $('.js-search').not($this),
			$btn = $this.find('.js-search-btn'),
			flagOpen = false;

		$btn.on('click', function(){
			$otherSearch.removeClass('is-open');

			if(!flagOpen){
				$this.addClass('is-open');
				return false;
			} else {
				return true;
			}
		});
	});

	/*burger*/

	$('.js-open-nav').on('click', function(){
		$('.js-target-nav').addClass('is-open');
		// $('html, body').css('overflow', 'hidden');
		return false;
	});

	$('.js-close-nav').on('click', function(){
		$('.js-target-nav').removeClass('is-open');
		$('html, body').removeAttr('style');
		return false;
	});

	/*Popup*/

	$('.js-popup-link').on('click', function(){
		var $this = $(this),
			popupId = $this.attr('data-popup'),
			$popups = $('.js-popup');

		$popups.removeClass('is-open');
		$('.js-popup[data-popup="' + popupId + '"]').addClass('is-open');
		//$('html, body').css('overflow', 'hidden');
		return false;
	});

	$('.js-popup-close').on('click', function(){
		var $this = $(this),
			$popup = $this.closest('.js-popup');

		$popup.removeClass('is-open');
		$('html, body').removeAttr('style');
		return false;
	});

	/*Slider carousel*/

	function initSlider($el){
		//$el.removeClass('slick-destroyed');
		
		$el.each(function(){
			var $this = $el,
				type = $this.data('type'),
				$sliderWrap = $this.closest('.js-slider-carousel-wrap'),
				$arrowsContainer = $sliderWrap.find('.js-slider-carousel-controls'),
				sliderOptionsDefault = {
					appendArrows: $arrowsContainer,
					prevArrow: '<button class="button button-arrow js-slider-carousel-prev"><svg class="svg-icon" viewBox="0 0 43 24.8" width="28px" height="16px"><use xlink:href="#svg-arrow-left"></use></svg></button>',
					nextArrow: '<button class="button button-arrow js-slider-carousel-next"><svg class="svg-icon" viewBox="0 0 43 24.8" width="28px" height="16px"><use xlink:href="#svg-arrow-right"></use></svg></button>'
				},
				sliderOptionsCustom,
				sliderOptions;

			switch (type) {
				case 'products':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 4,
						slidesToScroll: 4,
						responsive: [
							{
								breakpoint: 1366,
								settings: {
									slidesToShow: 3,
									slidesToScroll: 3,
								}
							},
							{
								breakpoint: 1025,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 2,
								}
							},
							{
								breakpoint: 640,
								settings: {
									variableWidth: false,
									slidesToShow: 2,
									slidesToScroll: 1
								}
							},
							{
								breakpoint: 639,
								settings: {
									variableWidth: false,
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					}
				break;
				case 'rows':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						rows: 1,
						slidesPerRow: 4,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									slidesPerRow: 2,
									slidesToShow: 1,
									slidesToScroll: 1,
									rows: 2
								}
							},
							{
								breakpoint: 640,
								settings: {
									variableWidth: true,
									slidesToShow: 1,
									slidesPerRow: 1,
									slidesToScroll: 1,
									rows: 1
								}
							}
						]
					}
				break;
				case 'actions':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 4,
						slidesToScroll: 4,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 2,
								}
							},
							{
								breakpoint: 640,
								settings: {
									variableWidth: true,
									slidesToShow: 1,
									slidesToScroll: 1,
								}
							}
						]
					}
				break;
				case 'news':
					sliderOptionsCustom = {
						infinite: false,
						slidesToShow: 3,
						slidesToScroll: 3,
						variableWidth: true,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									variableWidth: true,
									slidesToShow: 1,
									slidesToScroll: 1,
								}
							},
							{
								breakpoint: 640,
								settings: {
									variableWidth: true,
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					}
				break;
				case 'news-rows':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: false,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									variableWidth: false,
									slidesToShow: 2,
									slidesToScroll: 1,
								}
							},
							{
								breakpoint: 640,
								settings: 'unslick'
								// {
								// 	variableWidth: false,
								// 	slidesToShow: 1,
								// 	slidesToScroll: 1
								// }
							}
						]						
					}
				break;
				case 'certificates':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 3,
						slidesToScroll: 3,
						variableWidth: false,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									infinite: false,
									variableWidth: true,
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					}
				break;
				case 'certificates-rows':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						rows: 2,
						slidesPerRow: 4,
						variableWidth: false,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									infinite: false,
									variableWidth: true,
									slidesToShow: 1,
									slidesToScroll: 1,
									rows: 2,
									slidesPerRow: 1
								}
							},
							{
								breakpoint: 640,
								settings: 'unslick'
							}
						]
					}
				break;
				case 'vendors-rows':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						rows: 3,
						slidesPerRow: 5,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									infinite: false,
									slidesToShow: 1,
									slidesToScroll: 1,
									rows: 2,
									slidesPerRow: 2
								}
							},
							{
								breakpoint: 640,
								settings: 'unslick'
								// {
								// 	variableWidth: true,
								// 	infinite: false,
								// 	slidesToShow: 1,
								// 	slidesToScroll: 1,
								// 	rows: 1,
								// 	slidesPerRow: 1
								// }
							}
						]
					}
				break;
				case 'vendors-services-rows':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						rows: 2,
						slidesPerRow: 4,
						responsive: [
							{
								breakpoint: 1025,
								settings: {
									infinite: false,
									slidesToShow: 1,
									slidesToScroll: 1,
									rows: 2,
									slidesPerRow: 2
								}
							},
							{
								breakpoint: 640,
								settings: {
									infinite: false,
									variableWidth: true,
									slidesToShow: 1,
									slidesToScroll: 1,
									rows: 1,
									slidesPerRow: 1
								}
							}
						]
					}
				break;
				case 'catalog':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						mobileFirst: true,
						variableWidth: true,
						responsive: [
							{
								breakpoint: 639,
								settings: {
									variableWidth: false,
									slidesToShow: 2,
									slidesToScroll: 2
								}
							},
							{
								breakpoint: 1366,
								settings: {
									variableWidth: false,
									slidesToShow: 4,
									slidesToScroll: 4,
								}
							},
							{
								breakpoint: 1025,
								settings: {
									variableWidth: false,
									slidesToShow: 3,
									slidesToScroll: 3,
								}
							},
						]
					}
				break;
				case 'projects':
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: false,
						responsive: [
							// {
							// 	breakpoint: 640,
							// 	settings: {
							// 		slidesToShow: 1,
							// 	}
							// }
							{
								breakpoint: 1366,
								settings: {
									variableWidth: false,
									slidesToShow: 3,
									slidesToScroll: 1,
								}
							},
							{
								breakpoint: 1025,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1,
								}
							},
							{
								breakpoint: 640,
								settings: {
									variableWidth: false,
									slidesToShow: 2,
									slidesToScroll: 1
								}
							}, 
							{
								breakpoint: 639,
								settings: {
									variableWidth: false,
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					}
				break;
				default:
					sliderOptionsCustom = {
						infinite: true,
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}

			sliderOptions = Object.assign(sliderOptionsDefault, sliderOptionsCustom);

			if(!$this.is('.slick-initialized')){
				$this.slick(sliderOptions);
			}
		});
	}

	function destroySlider($el){
		$el.slick('unslick')
		//$el.addClass('slick-destroyed');
	}

	$('.js-slider-carousel').each(function(){
		// window.innerWidth > 768 && initSlider($(this));
		initSlider($(this));
	});

	$(window).on('resize', function(){
		//console.log('window.innerWidth', window.innerWidth);
		// if (window.innerWidth <= 768) {
		// 	$('.js-slider-carousel').each(function(){
		// 		if (!$(this).hasClass('slick-destroyed')) {
		// 			destroySlider($(this));
		// 		}
		// 	});
		// } else {
		// 	$('.js-slider-carousel').each(function(){
		// 		initSlider($(this));
		// 	});
		// }
		$('.js-slider-carousel').each(function(){
			window.innerWidth > 768 && initSlider($(this));
			initSlider($(this));
		});

	});
	
	/*
	test
	$(document).on('click', function(event) {
		console.log('click');
		
	    $('.js-slider-carousel').each(function(i, elt) {
			destroySlider($(this));
		});
	});*/

	/*tabs*/

	$('.js-tabs-link').on('click', function(){
		var $this = $(this),
			tab = $this.attr('data-tab'),
			$tabs = $this.closest('.js-tabs'),
			$tabsItem = $tabs.find('.js-tabs-item[data-tab="' + tab + '"]');

		$this.addClass('is-active').siblings().removeClass('is-active');
		$tabsItem.addClass('is-active').siblings().removeClass('is-active');
		$tabs.find('.js-slider-carousel').each(function(){
			destroySlider($(this));
		})
		$tabs.find('.js-slider-carousel').each(function(){
			initSlider($(this));
		})

		return false;
	});

	/*phone mask*/
	document.querySelectorAll('.js-phone-mask').forEach((el) =>{
		new Cleave(el, {
		    blocks: [0, 3, 3, 2, 2],
		    delimiters: ['(', ') ', '-', '-'],
		    numericOnly: true
		});
	})

	/*validation*/
	$('.js-form-offer').each(function(){
		$(this).validate({
			rules: {
				name: {
					required: true
				},
				email: {
					required: true,
					email: true
				},
				phone: {
					required: true
				},
				agreement: {
					required: true
				}
			},
			messages: {
			    name: "Введите ваше имя",
			    email: {
			    	required: "Введите ваш e-mail",
			    	email: "Введте корректный e-mail"
			    },
			    phone: {
			    	required: "Введите ваш номер телефона",
			    	minlength: "Введите корректный номер"
			    },
			    agreement: {
			    	required: ""
			    }
			},
			submitHandler: function(form) {
				$('.js-popup[data-popup="popup-success"]').addClass('is-open').siblings('.js-popup').removeClass('is-open');
			}
		});
	});

	$('.js-form-callback').each(function(){
		$(this).validate({
			rules: {
				name: {
					required: true
				},
				phone: {
					required: true
				},
				agreement: {
					required: true
				}
			},
			messages: {
			    name: "Введите ваше имя",
			    email: {
			    	required: "Введите ваш e-mail",
			    	email: "Введте корректный e-mail"
			    },
			    phone: {
			    	required: "Введите ваш номер телефона"
			    },
			    agreement: {
			    	required: ""
			    }
			},
			submitHandler: function(form) {
				$('.js-popup[data-popup="popup-success"]').addClass('is-open').siblings('.js-popup').removeClass('is-open');
			}
		});
	});

	/*autosize*/

	autosize($('.autosize'));

	/*fancybox*/

	$('[data-fancybox]').fancybox({
		clickSlide: "close",
		animationEffect: false,
		backFocus: false,
		mobile: {
		    clickSlide: "close"
		}
	});

	/*file upload*/

	function formatSizeUnits(bytes){
		if      (bytes>=1073741824) {bytes=Math.round(bytes/1073741824) +' Gb';}
		else if (bytes>=1048576)    {bytes=Math.round(bytes/1048576) +' Mb';}
		else if (bytes>=1024)       {bytes=Math.round(bytes/1024) +' Kb';}
		else if (bytes>1)           {bytes=bytes+' b';}
		else if (bytes==1)          {bytes=bytes+' b';}
		else                        {bytes='0 byte';}
		return bytes;
	}

	function handleFileUpload(e) {
		var files = e.target.files,
			$this = $(e.target),
			$container = $this.closest('.js-file'),
			$filesList = $container.find('.js-file-list');

		for (var i = 0, f; f = files[i]; i++) {
			$filesList.append('<div class="file-item js-file-target"><span class="file-item__title">' + f.name + ' <span class="file-item__size">(' + formatSizeUnits(f.size) + ')</span></span><button class="file-item__remove js-file-remove"><svg class="svg-icon" viewBox="0 0 30 30" width="12px" height="12px"><use xlink:href="#svg-close"></use></svg></button></div>');
		}
	}

	$('.js-file-upload').on('change', handleFileUpload);

	$(document).on('click', '.js-file-remove', function(){
		var $this = $(this),
			$target = $this.closest('.js-file-target');

		$target.remove();
		return false;
	});


	$(document).click(function(event) {
		if (!$(event.target).closest('.js-search').length) {
			$('.js-search').removeClass('is-open');
		}

		if (!$(event.target).closest('.nav-menu__wrap, .js-open-nav').length) {
			$('.js-target-nav').removeClass('is-open');
		}

		if (!$(event.target).closest('.popup__box, .js-popup-link').length) {
			$('.js-popup').removeClass('is-open');
		}

		if (!$(event.target).closest('.popup__box, .js-popup-link, .nav-menu__wrap, .js-open-nav').length){
			$('html, body').removeAttr('style');
		}
		event.stopPropagation();
	});

	/*
	* Show more
	* */
	function showMore() {
		$('.js-load-content').each(function () {
			var itemsToShow = $(this).data('items-to-show');
			var visibleItems = itemsToShow;
			var list = $(this).find('.js-list');

			// Hide items
			list.hide();

			list.children().each(function (i) {
				if ( i < itemsToShow ) {
					$(this).show()
				} else {
					$(this).hide()
				}
			});

			list.show();

			// Show next products
			$(this).find('.js-load-more').on('click', function (e) {
				e.preventDefault();

				list.children().each(function (i) {
					if (i > visibleItems && i < visibleItems + itemsToShow) {
						$(this).fadeIn()
					}
				});
				
				visibleItems += itemsToShow - 1;
				
				if (visibleItems >= list.children().length) {
					$(e.currentTarget).hide();
				}
			});
		});
	}

	$(window).on('resize', function(){
		$('.js-load-content').each(function(){
			window.innerWidth <= 768 && showMore($(this));
			showMore($(this));
		});
	});

	showMore();
});

/* dropdown*/

const switchDropdown = (dropdown, control) => {
	control.forEach ((btn, i, arr) => {
		btn && btn.addEventListener('click', (event) => {
			event.preventDefault();
			let dropdown = btn.closest('.js-dropdown');
			dropdown.classList.toggle('is-open', !dropdown.classList.contains('is-open'));
		});
	})
};

const switchDropdownNav = (dropdown, control, close) => {
	control.forEach((btn, i, arr) => {
		btn && btn.addEventListener('click', (event) => {
			event.preventDefault();
			let dropdown = btn.closest('.js-nav-dropdown');
			dropdown.classList.toggle('is-open', !dropdown.classList.contains('is-open'));
		})
	})

	close.addEventListener('click', (event) => {
		dropdown.forEach ((el, i, arr) => {
			el.classList.remove('is-open', !el.classList.contains('is-open'));
		})
	})
}


window.addEventListener('DOMContentLoaded', () => {
	const DOM = {
		dropdown: document.querySelector('.js-dropdown'),
		switchDropdown: document.querySelectorAll('.js-open-dropdown'),
		dropdownNav: document.querySelectorAll('.js-nav-dropdown'),
		switchDropdownNav: document.querySelectorAll('.js-nav-open-dropdown'),
		closeDropdownNav: document.querySelector('.js-close-nav')
	}

	switchDropdown(DOM.dropdown, DOM.switchDropdown);
	switchDropdownNav(DOM.dropdownNav, DOM.switchDropdownNav, DOM.closeDropdownNav);
});